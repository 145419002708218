import { Routes, Route } from 'react-router-dom';
import './index.css';
import NotFound from './views/pages/NotFound/NotFound';
import ApplicationSent from "./views/pages/ApplicationSent/ApplicationSent";
import SendApplicationRequest from "./views/pages/SendApplicationRequest/SendApplicationRequest";

function App() {
  return (
      <Routes>
          <Route path="send-application-request/:id" element={<SendApplicationRequest/>}/>
          <Route path="application-sent" element={<ApplicationSent/>}/>
          <Route path="" element={<NotFound/>}/>
          <Route path="*" element={<NotFound/>}/>
      </Routes>
  );
}

export default App;
