import './ApplicationSent.scss'
import arrow from './images/arrow.png';
import {Link, useNavigate} from "react-router-dom";

function ApplicationSent({url}) {
    const navigate = useNavigate();

    return (
        <section className="application-sent">
            <div className="page">
                <img src={arrow} alt="" className="application-sent__image"/>
                <h2 className="application-sent__title">Заявка отправлена</h2>
                <p className="application-sent__text">
                    Наши специалисты свяжутся с Вами в ближайшее время для уточнения деталей заявки. Пожалуйста, оставайтесь на связи.
                </p>
                <Link className="application-sent__button" to="/send-application-request" onClick={navigate(url)}>
                    Вернуться на главную
                </Link>
            </div>
        </section>
    );
}

export default ApplicationSent;
