import './SendApplicationRequest.scss'
import logo from "./images/logo.png"
import InputMask from 'react-input-mask';
import {useEffect, useRef, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import * as api from '../../../utils/MainApi';
import Preloader from "../../common/components/Preloader/Preloader";
import arrow from "../ApplicationSent/images/arrow.png";

function SendApplicationRequest() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isApplicationSent, setIsApplicationSent] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);
    const [shopName, setShopName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const termRef = useRef();

    const amountRef = useRef();
    const [isAmountValid, setIsAmountValid] = useState(0);
    const [amountInputError, setAmountInputError] = useState('');

    const iinRef = useRef();
    const [isIinValid, setIsIinValid] = useState(0);
    const [iinInputError, setIinInputError] = useState('');

    const phoneRef = useRef("");
    const [isPhoneValid, setIsPhoneValid] = useState(0);
    const [phoneInputError, setPhoneInputError] = useState('');

    useEffect(() => {
        setIsLoading(true)
        api.getShopInfo(id)
            .then((res) => {
                setShopName(res.data.name);
            })
            .catch((err) => {
                console.log(err);
                navigate('/');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, navigate])

    useEffect(() => {
        if (isAmountValid && isIinValid && isPhoneValid) {
            setIsFormValid(true);
            return;
        }
        setIsFormValid(false);
    }, [isAmountValid, isIinValid, isPhoneValid, isFormValid]);

    function validateAmount() {
        let isInputValid = true;
        let errorText = "";

        if (!/[0-9]+/.test(amountRef.current.value)) {
            errorText = 'Поле "Сумма" должно быть целым числом.';
            isInputValid = false;
        }

        if (!amountRef.current.validity.valid) {
            errorText = amountRef.current.validationMessage;
            isInputValid = false;
        }

        setIsAmountValid(isInputValid);
        setAmountInputError(errorText);
    }

    function validateIin() {
        let isInputValid = true;
        let errorText = "";
        let iinRegex = /\d{12}/;

        if (!iinRef.current.validity.valid) {
            errorText = iinRef.current.validationMessage;
            isInputValid = false;
        }

        if (!iinRegex.test(iinRef.current.value)) {
            errorText = 'Заполните это поле.';
            isInputValid = false;
        }

        setIsIinValid(isInputValid);
        setIinInputError(errorText);
    }

    function validatePhone(e) {
        e.preventDefault();
        let regexPhone = /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/;
        let isInputValid = true;
        let errorText = "";

        if (!regexPhone.test(phoneRef.current.value)) {
            errorText = 'Заполните это поле.';
            isInputValid = false;
        }

        setIsPhoneValid(isInputValid);
        setPhoneInputError(errorText);
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        api.sendApplicationRequest(
                termRef.current.value,
                iinRef.current.value,
                amountRef.current.value,
                phoneRef.current.value.match(/\d/g).join(''),
                id
            ).then((res) => {
                setIsApplicationSent(true)
            })
            .catch((err) => {
                console.log('EEE', err.data);
                alert('Заявка уже создана')
            })
            .finally(() => {
                setIsLoading(false);
                setIsFormValid(false);
                setIsAmountValid(0);
                setIsIinValid(0);
                setIsPhoneValid(0);
            });
    }

    function hideSuccessModal() {
        setIsApplicationSent(false);
    }

    if (isApplicationSent) {
        return <section className="application-sent">
            <div className="page">
                <img src={arrow} alt="" className="application-sent__image"/>
                <h2 className="application-sent__title">Заявка отправлена</h2>
                <p className="application-sent__text">
                    Наши специалисты свяжутся с Вами в ближайшее время для уточнения деталей заявки. Пожалуйста, оставайтесь на связи.
                </p>
                <div className="application-sent__button" onClick={hideSuccessModal}>
                    Вернуться на главную
                </div>
            </div>
        </section>
    }

    if (isLoading) {
        return <Preloader/>
    }

    return (
        <section className="send-application-request">
            <div className="page">
                <h2 className="application-sent__title">Рассрочка в магазине {shopName}</h2>

                <form id="form" noValidate onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label className="form-check form-check-inline" htmlFor="inlineRadio1">
                            <input className="form-check-input" type="radio" name="term" id="inlineRadio1" value="zaimInkarInstallment-3" ref={termRef} defaultChecked/>
                            <span className="form-check-text">
                                <img src={logo} alt=""/>
                                Рассрочка на 3 месяца
                            </span>
                        </label>
                        <label className="form-check form-check-inline" htmlFor="inlineRadio2">
                            <input className="form-check-input" type="radio" name="term" id="inlineRadio2" value="zaimInkarInstallment-6" ref={termRef}/>
                            <span className="form-check-text">
                                <img src={logo} alt=""/>
                                Рассрочка на 6 месяцев
                            </span>
                        </label>
                        <label className="form-check form-check-inline" htmlFor="inlineRadio3">
                            <input className="form-check-input" type="radio" name="term" id="inlineRadio3" value="zaimInkarInstallment-9" ref={termRef}/>
                            <span className="form-check-text">
                                <img src={logo} alt=""/>
                                Рассрочка на 9 месяцев
                            </span>
                        </label>
                        <label className="form-check form-check-inline" htmlFor="inlineRadio4">
                            <input className="form-check-input" type="radio" name="term" id="inlineRadio4" value="zaimInkarInstallment-12" ref={termRef}/>
                            <span className="form-check-text">
                                <img src={logo} alt=""/>
                                Рассрочка на 12 месяцев
                            </span>
                        </label>
                    </div>

                    <label className={`application-request__field ${isIinValid !== 0 && !isIinValid ? 'has-error' : ''}`}>
                        ИИН
                        <input
                            ref={iinRef}
                            name="iin"
                            className="profile__input"
                            id="iin-input"
                            inputMode="numeric"
                            minLength="12"
                            maxLength="12"
                            required
                            onChange={validateIin}
                        />
                        <span className="input-error">{iinInputError}</span>
                    </label>

                    <label className={`application-request__field ${isAmountValid !== 0 && !isAmountValid ? 'has-error' : ''}`}>
                        Сумма
                        <input
                            ref={amountRef}
                            name="amount"
                            className="profile__input"
                            id="amount-input"
                            type="number"
                            min="0"
                            required
                            onChange={validateAmount}
                        />
                        <span className="input-error">{amountInputError}</span>
                    </label>

                    <label className={`application-request__field ${isPhoneValid !== 0 && !isPhoneValid ? 'has-error' : ''}`}>
                        Телефон
                        <InputMask
                            mask="+7 (999) 999 99 99"
                            name="phone"
                            className="profile__input"
                            id="phone-input"
                            required
                            onChange={validatePhone}
                            ref={phoneRef}
                        />
                        <span className="input-error">{phoneInputError}</span>
                    </label>
                    <button
                        type="submit"
                        disabled={!isFormValid}
                        className={
                            isFormValid
                                ? 'profile__button-save'
                                : 'profile__button-save form__button-save_inactive'
                        }>
                        Отправить заявку
                    </button>
                </form>
            </div>
        </section>
    );
}

export default SendApplicationRequest;
