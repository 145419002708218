import './NotFound.scss'
import left from './images/left.svg';
import right from './images/right.svg';
import {Link, useNavigate} from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();

    return (
        <section className="not-found">
            <div className="page">
                <h2 className="not-found__title">404</h2>
                <p className="not-found__description">Страница не найдена!</p>
                <p className="not-found__text">
                    К сожалению, запрошенная вами страница не была найдена. Пожалуйста, вернитесь на главную страницу!
                </p>
            </div>
            <div className="not-found__images">
                <img src={left} alt=""/>
                <img src={right} alt=""/>
            </div>
            <div className="page">
                <Link className="not-found__button" to="/send-application-request" onClick={navigate(-1)}>
                    Вернуться на главную
                </Link>
            </div>
        </section>
    );
}

export default NotFound;
